<template>
  <div>
    <TopParallax
      title="REASON FOR THE CREATION OF THE FOUNDATION"
      :img-src="imgSrc"
    />
    <AboutBar />
    <FoundationContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import AboutBar from '@/components/hope/AboutBar.vue'
import FoundationContent from '@/components/hope/FoundationContent.vue'

export default {
  components: {
    TopParallax,
    AboutBar,
    FoundationContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide1.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Foundation'
      }
    }
  }
}
</script>
