<template>
  <v-card rounded="0">
    <v-container>
      <FoundationCarousel />
    </v-container>
    <v-card rounded="0" color="#f3f2ed">
      <v-container>
        <h2 class="h4 text-center py-4">HOPE AND ANGELS FOUNDATION was created with the aim of mitigating the devastating effects of poverty on Helpless Little Angels -- vulnerable children in Angola.</h2>
        <v-card rounded="0" class="mb-8">
          <v-container>
            <h5 class="h5 my-3">In Angola, the social and economic disruption caused by more than thirty years of civil war has devastated the interior of the country. The war has weakened not only the country’s infrastructure but has also left thousands of people homeless. During the war, the conflicting parties planted landmines everywhere leaving countless thousands of farmers destitute and unable to work in their farm fields due to the danger caused by landmines. Families fleeing the minefields are subjected to hunger and misery. They have joined thousands in the big cities creating demographic networks of extreme poverty within the urban space of each city.</h5>
            <h5 class="h5 my-3">This disorderly migration of impoverished farmers to the great cities sits on the margin of society. Luanda, the capital city of Angola has seen a growth from 600,000 upwards to 6 million people within a very short period. The city does not have the infrastructure to accommodate this influx of population. The living conditions of these migrant farmers is appalling. Children lack everything. It is frequent to see groups of children marauding around the city looking for food or scavenging through rubbish for something to eat.</h5>
            <h5 class="h5 my-3">The war has transformed the traditional and formal economy of Angola. A gigantic informal economy has been created which has rapidly pushed more than 70% of the population below the poverty line. Due to the war, social and economic issues have been fractured. This has perverted traditional values, norms, and culture.</h5>
            <h5 class="h5 my-3">Angola is a country with very rich soil and a diversity of minerals. However, its economy is based on the foreign sales of oil and diamonds only. Because of the richness of its soil, Angola, theoretically, has the potential of becoming a food producing powerhouse. However, due to the slow progress in demining the fields of landmines, farmers cannot rely on the land for their sustenance.</h5>
            <h5 class="h5 my-3">With little more than 15 years of peace, Angola is still in a very slow process of development, restructuring, rehabilitation and construction of social infrastructure in all areas. These are needed for the diversification of the country's economy.</h5>
            <h5 class="h5 my-3">Angola has been plunged into a deep economic and financial crisis. While the government faces these severe issues, it is also attempting to develop a favourable political environment and to establish safe policies and tools for fighting corruption at the highest level of public management. For these reasons, a better quality of life is not foreseen for the citizens of Angola in the near future. Children, women (who are the majority of the population) and the elderly are the most vulnerable and most affected by this situation.</h5>
            <h5 class="h5 my-3">In the time period from the civil war until the peace conquest in February 2002, many families were disrupted by the death of their parents. Many children became orphaned by one or both parents. Others, in an attempt to save their lives, fled and joined in with large groups of people. As a consequence, numerous children have completely lost contact with their families.</h5>
            <h5 class="h5 my-3">Because of HIV/AIDS, war, and other factors, many widowed women are raising seven or more children on their own. Polygamy is rampant and men frequently abandon their spouses or partners because they have taken on other wives. Teen pregnancy is another social scourge. Young girls and young women who become pregnant early have no skills or education and find themselves destitute while caring for their children alone. The country is also struggling with high levels of unemployment -- above 60%.</h5>
            <h5 class="h5 my-3">Because women have less education and few marketable skills, they face even higher levels of unemployment. They usually have poorly paid jobs such as domestic employees and informal goods sellers. They have no social security. Being street sellers, "Zungueiras," is one the few work options that many have to support their children. These so-called "Zungueiras", by the nature of their activity, are also exposed to a great risk in their daily lives and are subjected to bribes from police and other public officials. The police, to whom they have to pay commissions, frequently confiscate their goods. They are beaten and mistreated by the police. At the end of a day of hard work, they often return home empty-handed because the meager income that they have earned during the day was either confiscated or robbed by the police.</h5>
          </v-container>
        </v-card>
        <v-card rounded="0" class="mb-4">
          <v-container>
            <h5 class="h5 my-3">In addition, many young mothers engage in passive and/or active prostitution as a way of seeking some sustenance for themselves and their children. They run a high level of risk, however, of becoming contaminated with a number of sexually transmitted diseases including HIV.</h5>
            <h5 class="h5 my-3">The question then arises -- While the mothers are on this journey from dawn to sunset searching for a means of survival, who cares for their children? Who feeds them, cares for them, and protects them? The answer is -- NO ONE! It’s these children Hope and Angels Foundation calls "angels".</h5>
            <h5 class="h5 my-3">Children are relegated to "involuntary abandonment" by their mothers or any other family member responsible for them. They play in the streets and eat anything they find on the ground or whatever someone gives to them. Many hungry children fall asleep at the sides of the roads while they wait over many long hours for their mothers to return. It’s an endless wait day after day after day.</h5>
            <h5 class="h5 my-3">The situations mentioned above contribute to the widespread phenomenon of "Children of the Street" and “Street Children ". The presence of these children in the streets is also compounded by the ill-treatment of relatives who, in the absence of biological parents, treat them badly.</h5>
            <h5 class="h5 my-3">Street children, and even those who may have a relative caring for them, are totally and constantly exposed to diverse dangers. They suffer all kinds of abuses including psychological, verbal, sexual, forced child labour, and others.</h5>
            <h5 class="h5 my-3">There are a few cases reported on TV, radio and state and private newspapers of homeless children who are sexually abused, raped by older neighbours, and even religious leaders. These perpetrators take advantage of the parents' long absences, especially the mothers, from their children. Many of these children suffocate their sufferings by inhaling drugs and gasoline. Most of these children, between the ages of 3 and 15, are outside the formal education system.</h5>
            <h5 class="h5 my-3">Another factor of equal importance concerns cultural and even religious issues. Many children, even at a young age, are accused of being sorcerers by relatives (which may include father, mother or other family members). Often formal religious leaders and traditional witch doctors, “quimbandeiros,” accuse them of being sorcerers. These children who are labeled as sorcerers are also abandoned on the streets where they end up being raped as well as psychologically and physically tortured. Sometimes they have their organs mutilated. In certain cases, children are burned in rituals of these religious groups as a way of allegedly freeing them of the power and spirits of witchcraft or Satan.</h5>
            <h5 class="h5 my-3">Although Angola is now known internationally as a country rich in resources and possessing rich soils which could bring economic and social prosperity through agriculture, the political and military environment is not safe. The attainment of peace is a distant dream. Even though the government is making significant efforts to reduce the level of poverty and is creating more opportunities for development and employment for young people, there is still a lot to be done and it is urgent! At this time, the government is not yet prepared to address and provide answers and resources aimed at alleviating the main problems directly affecting families, particularly children and women.</h5>
            <h5 class="h5 my-3">This is the reason HOPE AND ANGELS FOUNDATION was created by Esperança Baião Alfico. Esperanca and a group of collaborators in Canada are working together to develop actions aimed at reducing the suffering of our main target group – Children -- Our Helpless Little Angels. Hope and Angels Foundation also advocates in defense for the rights and security of these disadvantaged children in the international and national community.</h5>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
import FoundationCarousel from '@/components/hope/FoundationCarousel.vue'

export default {
  components: {
    FoundationCarousel
  }
}
</script>
