<template>
  <v-carousel
    class="pa-0"
    cycle
    height="auto"
    :show-arrows-on-hover="true"
    :hide-delimiters="false"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      fade
      eager
    >
      <v-img :src="item.src" eager/>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/foundation/home3.jpg')
        },
        {
          src: require('@/assets/images/foundation/home2w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home4w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home5.jpg')
        },
        {
          src: require('@/assets/images/foundation/home6w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home7.jpg')
        },
        {
          src: require('@/assets/images/foundation/home8.jpg')
        },
        {
          src: require('@/assets/images/foundation/home9w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home10_2.jpg')
        },
        {
          src: require('@/assets/images/foundation/home11w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home12w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home13.jpg')
        },
        {
          src: require('@/assets/images/foundation/home14w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home15w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home16w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home17w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home18w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home19.jpg')
        },
        {
          src: require('@/assets/images/foundation/home20.jpg')
        },
        {
          src: require('@/assets/images/foundation/home21w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home22w.jpg')
        },
        {
          src: require('@/assets/images/foundation/home23w.jpg')
        }
      ]
    }
  }
}
</script>